const card = ngModule => {

  /**
   * @ngdoc component
   * @name neptuneApp.component:card
   * @description
   *   A card
   */

  ngModule.component('nepCard', {
    bindings: {

      /**
       * Is the card in loading state?
       * @type {Boolean}
       */
      loading: '<',
    },
    transclude: true,
    template: `
      <div class="card" ng-if="!$ctrl.loading">
        <ng-transclude></ng-transclude>
      </div>

      <div class="card" ng-if="$ctrl.loading">
        <i class="fa fa-spinner fa-spin"></i> Loading...
      </div>
    `,
  });
};

export default card;
