// Service for getting participants.
//
// Usage:
//
// import stripToken from 'utils/stripToken';
//
// var rawToken = 's.m.archer@isis.biz';
// var participant = TritonParticipant.get({
//   code,
//   token: stripToken(rawToken)
// });
//
// var newParticipant = new TritonParticipant({
//   student_id: rawToken,
//   stripped_student_id: stripToken(rawToken),
//   team_id: 'Team_ISIS',
// });
//
// newParticipant.$save({ code: 'foo-bar' })

const tritonParticipantService = ngModule => {
  ngModule.service('TritonParticipant', function TritonParticipant(
    $resource,
    tritonDomain,
  ) {
    const TritonParticipantResource = $resource(
      `//${tritonDomain}/api/codes/:code/participants/:token`,
    );

    return TritonParticipantResource;
  });
};

export default tritonParticipantService;
