(function () {
  'use strict';

  /**
   * @ngdoc component
   * @name appParticipate.component:nepCleverPortal
   * @description
   *    Form to identify a participant based on Clever SSO
   */

  window.ngModule.component('nepCleverPortal', {
    bindings: {
      /**
       * Parent can bind to this event. Called when token value changes.
       * @type {Function}
       */
      setToken: '&',

      /**
       * Parent can bind to this event. Called when Clever SSO fails.
       * @type {Function}
       */
      setTokenError: '&',

      /**
       * Parent can bind to this event. Called when token value changes.
       * @type {Function}
       */
      submitToken: '&',

      /**
       * Optional text to display in form.
       * @type {string}
       */
      portalMessage: '<',

      /**
       * Whether there's an error message we need to display.
       * @type {boolean}
       */
      isError: '<',
    },
    controller: CleverPortalController,
    template: require('./clever_portal.view.html'),
  });
})();

function CleverPortalController($scope, CleverAuth) {
  const vm = this;

  this.$onInit = function () {
    CleverAuth.initialize();

    CleverAuth.onAuthStateChanged(
      function onSuccess(user) {
        if (user) {
          // User has logged in with Clever.
          $scope.$apply(() => {
            vm.token = user.email;
            vm.setToken({ token: user.email });

            if (!vm.isError) {
              vm.submitToken({ options: { agreeTerms: true } });
            }
          });
        }
        // else they haven't logged in yet
      },
      function onError(error) {
        // See https://github.com/firebase/firebaseui-web?tab=readme-ov-file#starting-the-sign-in-flow
        // for examples of error handling.
        console.error(error);
        $scope.$apply(() => {
          vm.setTokenError({
            errorMessage: "Could not log in to Clever. Please try another " +
              "account or ask your teacher for help."
          });
        });
      }
    );
  };

  this.$postLink = function () {
    CleverAuth.startUi();
  };
}
