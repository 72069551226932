import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

const googleAuthService = ngModule => {
  ngModule.service('GoogleAuth', GoogleAuth);

  function GoogleAuth(googleAuthApiKey, googleAuthDomain, googleTenantId) {
    const uiConfig = {
      signInFlow: 'popup',
      // We don't need firebaseui to do any redirecting for us, rather
      // the onAuthStateChanged event listener will take over.
      signInSuccessUrl: undefined,
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: {
            // https://github.com/firebase/firebaseui-web#configure-oauth-providers
            // Forces account selection even when one account is available. This
            // gives the user the opportunity to select the account they were
            // invited with.
            prompt: 'select_account',
          },
        },
      ],
      // Prevents any additional redirect after we return to the app. This is
      // important since the auth data does not presist between reloads.
      callbacks: { signInSuccessWithAuthResult: () => false },
    };
    let ui;

    firebase.initializeApp({
      apiKey: googleAuthApiKey,
      authDomain: googleAuthDomain,
    });

    // Don't store auth information anywhere other than JavaScript memory.
    // On page change or refresh, it will be lost. This is exactly what we
    // want so other participants walking up to the same terminal won't
    // accidentially get a previous session.
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

    return {
      initialize() {
        if (!ui) {
          const auth = firebase.auth();
          ui = new firebaseui.auth.AuthUI(auth);
          auth.tenantId = googleTenantId;
        }
      },

      onAuthStateChanged(callback) {
        firebase.auth().onAuthStateChanged(callback);
      },

      startUi(id = 'firebaseui-auth-container') {
        ui.start(`#${id}`, uiConfig);
      },
    };
  }
};

export default googleAuthService;
