import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

const cleverAuthService = ngModule => {
  ngModule.service('CleverAuth', CleverAuth);

  function CleverAuth(googleAuthApiKey, googleAuthDomain, googleTenantId) {
    const uiConfig = {
      signInFlow: 'popup',
      // We don't need firebaseui to do any redirecting for us, rather
      // the onAuthStateChanged event listener will take over.
      signInSuccessUrl: undefined,
      signInOptions: [
        {
          provider: 'oidc.clever',
          providerName: 'Clever',
          // Designed to match assets Clever specifies.
          // See https://dev.clever.com/docs/clever-sso-assets
          fullLabel: "Log in with Clever",
          // Clever's blue is #1464FF (https://www.clever.com/about/media-kit)
          // but the icon we use is blue, so use a white background.
          buttonColor: '#FFFFFF',
          iconUrl: '/static/images/logo_clever_square.png',
          customParameters: {
            // If the student is already logged in, always confirm their
            // identity, in case this is a shared device.
            // https://dev.clever.com/docs/best-practices-edge-cases#shared-devices-for-students
            confirmed: 'false',
          },
        },
      ],
      // Prevents any additional redirect after we return to the app. This is
      // important since the auth data does not presist between reloads.
      callbacks: { signInSuccessWithAuthResult: () => false },
    };
    let ui;

    firebase.initializeApp({
      apiKey: googleAuthApiKey,
      authDomain: googleAuthDomain,
    });

    // Don't store auth information anywhere other than JavaScript memory.
    // On page change or refresh, it will be lost. This is exactly what we
    // want so other participants walking up to the same terminal won't
    // accidentially get a previous session.
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

    return {
      initialize() {
        if (!ui) {
          const auth = firebase.auth();
          ui = new firebaseui.auth.AuthUI(auth);
          auth.tenantId = googleTenantId;
        }
      },

      onAuthStateChanged(callback) {
        firebase.auth().onAuthStateChanged(callback);
      },

      startUi(id = 'firebaseui-auth-container') {
        ui.start(`#${id}`, uiConfig);
      },
    };
  }
};

export default cleverAuthService;
