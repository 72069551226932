const button = ngModule => {

  /**
   * @ngdoc component
   * @name neptuneApp.component:button
   * @description
   *   A button
   */

  ngModule.component('nepButton', {
    bindings: {

      /**
       * The button id. Unfortunately, you can't just pass in `id` because
       * Angular will place that attribute on the component wrapper, which
       * results in multiple elements with the same id. So we need to pass in
       * a meaningless attribute and then use that on the element we actually
       * want to assign the id to.
       */
      setId: '@',

      /**
       * Is the button in disabled state?
       * @type {Boolean}
       */
      disabled: '<',

      /**
       * Is the button in loading state?
       * @type {Boolean}
       */
      loading: '<',

      /**
       * Function called when the button is clicked.
       * @type {Function}
       */
      onClick: '&',
    },
    transclude: true,
    template: `
      <button
        id="{{$ctrl.setId}}"
        type="submit"
        ng-disabled="$ctrl.disabled || $ctrl.loading"
      >
        <div class="button-text">
          <ng-transclude></ng-transclude>
        </div>
        
        <div class="button-icon">
          <i class="fa fa-chevron-right" ng-hide="$ctrl.loading"></i>
          <i class="fa fa-spinner fa-spin" ng-show="$ctrl.loading"></i>
        </div>
      </button>
    `,
  });
};

export default button;
