(function () {
  'use strict';

  /**
   * @ngdoc component
   * @name appParticipate.component:nepPresurveyCleverConfirmation
   * @description
   *   When using Clever SSO, always display an extra page confirming what
   *   account is logged in.
   *   - Because Clever requires this for application approval
   *     + "Provide clear indication to users that they are logged in to the correct account."
   *     + "Provide a "Log Out" button."
   *     + See https://dev.clever.com/docs/district-sso-certification-guide#integration-requirements
   *   - Because Clever is aggressive in storing cookies and preserving student
   *     logins. It's _likely_ that students on a shared device will find
   *     previous Clever logins still active. They should have a way to log out
   *     and change to their account.
   */

  window.ngModule.component('nepPresurveyCleverConfirmation', {
    require: {
      // Allows $ctrl.parent.nextPresurveyState in markup.
      parent: '^nepPresurvey',
    },
    controller: PresurveyCleverConfirmationController,
    template: require('./presurvey_clever_confirmation.view.html'),
  });

  PresurveyCleverConfirmationController.$inject = [];

  function PresurveyCleverConfirmationController() {
    const vm = this;

    // By default this page doesn't display. It will switch on if necessary.
    vm.mask = true;

    vm.$onInit = function () {
      vm.parent.getLoadedData().then(loaded => {
        if (loaded.projectCohort.portal_type === 'clever') {
          // Clever SSO is active, and the user has been redirected back to us
          // with their Clever email. Display it and allow user to confirm or
          // log out.
          vm.cleverEmail = loaded.token;
          vm.mask = false;
          vm.parent.toggleMask(false);
        } else {
          // Some other portal type is active. This component is not relevant.
          // Do not display/unmask, and move on.
          vm.parent.getLoadedData().then(vm.parent.nextPresurveyState);
        }
      });
    };
  }

  // console.log('IIFE loaded component', PresurveyCleverConfirmationController)
})();
