(function() {
  'use strict';

  /**
   * @ngdoc component
   * @name appParticipate.component:nepGooglePortal
   * @description
   *    Form to identify a participant based on Google SSO
   */

  window.ngModule.component('nepGooglePortal', {
    bindings: {
      /**
       * Parent can bind to this event. Called when token value changes.
       * @type {Function}
       */
      setToken: '&',

      /**
       * Parent can bind to this event. Called when token value changes.
       * @type {Function}
       */
      submitToken: '&',

      /**
       * Optional text to display in form.
       * @type {string}
       */
      portalMessage: '<',

      /**
       * Whether there's an error message we need to display.
       * @type {boolean}
       */
      isError: '<',
    },
    controller: GooglePortalController,
    template: require('./google_portal.view.html'),
  });
})();

function GooglePortalController($scope, GoogleAuth) {
  const vm = this;

  this.$onInit = function() {
    GoogleAuth.initialize();

    GoogleAuth.onAuthStateChanged(function(user) {
      if (user) {
        $scope.$apply(() => {
          vm.token = user.email;
          vm.setToken({ token: user.email });

          if (!vm.isError) {
            vm.submitToken({ options: { agreeTerms: true } });
          }
        });
      }
    });
  };

  this.$postLink = function() {
    GoogleAuth.startUi();
  };
}
