(function () {
  'use strict';

  /**
   * @ngdoc component
   * @name appParticipate.component:nepNameOrId
   * @description
   *    Form to identify a participant based on a single text field.
   */

  window.ngModule
    .component('nepNameOrId', {
      bindings: {

        /**
         * Parent can bind to this event. Called when token value changes.
         * @type {Function}
         */
        setToken: '&',

        /**
         * Optional text to display in form.
         * @type {string}
         */
        portalMessage: '<',
      },
      template: require('./name_or_id.view.html'),
    });
})();
