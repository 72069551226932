/**
 * Remove characters from the identifiers that survey participants type in
 * to collapse the name space a little, hopfully making it easier to match
 * yourself on re-entry, even if, for instance, you're not consistent with
 * the periods in the beginning portion of your email address.
 *
 * @param  {string} rawToken what the user typed in
 * @return {string}          lower case, alphanumeric characters only
 */
function stripToken(rawToken) {
  return typeof rawToken === 'string'
    ? rawToken.toLowerCase().replace(/[^a-z0-9]/g, '')
    : undefined;
}

export default stripToken;
