const checkbox = ngModule => {

  /**
   * @ngdoc component
   * @name neptuneApp.component:checbox
   * @description
   *   A checkbox
   */

  ngModule.component('nepCheckbox', {
    bindings: {
      // Allow check/uncheck to change value in parent scope.
      // TODO? The onClick handler should probably make the change, but that's
      // not currently happening and I don't want to change controller logic at
      // the moment if I can help it.
      ngModel: '=',
      onClick: '&',
    },
    transclude: true,
    template: `
      <div class="checkbox">
        <input
          type="checkbox"
          ng-model="$ctrl.ngModel"
          ng-change="$ctrl.onClick()"
        >

        <div class="styled-checkbox" ng-class="{ 'checked': $ctrl.ngModel }">
          <svg class="styled-checkbox-icon" viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </svg>
        </div>
      </div>
    `,
  });
};

export default checkbox;
