import moment from 'moment';

/**
 * FOR COPILOT ASCEND 20 ONLY
 *
 * Sets `show_validation_follow_up=true` between specific dates in 2020.
 *
 * @param {Object} ngModule angular module "participate"
 * @returns {undefined}
 * @see https://github.com/PERTS/neptune/issues/1289
 */
const presurveyAscend20Validation = ngModule => {
  function controller($q, serverTime) {
    const vm = this;

    vm.$onInit = function() {
      const validationActive =
        moment(serverTime).isSameOrAfter('2020-11-01T00:00:00Z') &&
        moment(serverTime).isSameOrBefore('2020-12-31T23:59:59Z');
      const params = validationActive
        ? { show_validation_follow_up: 'true' }
        : {};
      vm.parent
        .setSurveyParams(params)
        .then(vm.parent.nextPresurveyState)
        .catch(vm.parent.presurveyError);
    };
  }

  ngModule.component('nepAscend20Validation', {
    controller,
    require: {
      parent: '^nepPresurvey',
    },
  });
};

export default presurveyAscend20Validation;
