export default ngModule => {
  function controller() {
    this.theSystemIsDown = false;
  }

  const template = `
    <div class="app-container">
      <div class="header" ng-hide="$state === undefined || $state.is('portal')">
        <a ui-sref="portal">
          <i class="fa fa-chevron-left"></i> Click here to start over
        </a>
      </div>

      <div ng-show="$ctrl.theSystemIsDown" class="card info">
        <nep-card-title>System Maintenance</nep-card-title>
        PERTS websites will be closed for maintenance for 2 hours on Sunday,
        April 28th, at 4pm pacific time.
      </div>

      <ui-view></ui-view>

      <div class="footer">
        <p>
          <!-- Company name intentionally omitted. -->
          &copy; {{ server_time.year }} All Rights Reserved.
        </p>
      </div>
    </div>
  `;

  ngModule.component('app', {
    controller,
    template,
  });
};
