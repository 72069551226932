const cardTitle = ngModule => {

  /**
   * @ngdoc component
   * @name neptuneApp.component:cardTitle
   * @description
   *   A card title
   */

  ngModule.component('nepCardTitle', {
    transclude: true,
    template: `
      <h1 class="card-title">
        <ng-transclude></ng-transclude>
      </h1>
    `,
  });
};

export default cardTitle;
