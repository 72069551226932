/**
 * Displays assent text to EP participants and offers the choice to continue or
 * opt out.
 * @param  {Object} ngModule participate module
 * @return {undefined}
 */
const presurveyEpAssent = ngModule => {
  function controller() {
    const ASSENT_PD_KEY = 'ep_assent';
    const vm = this;

    vm.doesNotAssent = false;

    vm.$onInit = function() {
      vm.parent.getLoadedData().then(loaded => {
        const assentPd = loaded.pdArr.find(pd => pd.key === ASSENT_PD_KEY);
        if (assentPd) {
          // This participant has already responded to our assent prompt,
          // don't show it again.
          vm.parent.nextPresurveyState();
        } else {
          // This participant hasn't seen the assent prompt yet; display it.
          vm.parent.toggleMask();
        }
      });
    };

    vm.continue = function() {
      vm.parent
        .getLoadedData()
        .then(loaded => {
          const assentPd = vm.parent.createPd(
            loaded,
            ASSENT_PD_KEY,
            vm.doesNotAssent ? 'false' : 'true',
          );
          return assentPd.$save();
        })
        .then(vm.parent.nextPresurveyState);
    };
  }

  ngModule.component('nepEpAssent', {
    controller,
    template: require('./index.view.html'),
    require: {
      parent: '^nepPresurvey',
    },
  });
};

export default presurveyEpAssent;
