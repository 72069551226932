import nepButton from './button';
import nepCard from './card';
import nepCardTitle from './cardTitle';
import nepCheckbox from './checkbox';
import presurveyAscend20Validation from './presurveyAscend20Validation';
import presurveyEpAssent from './presurveyEpAssent';
import presurveyEpBlockSwitcher from './presurveyEpBlockSwitcher';

const registerComponents = ngModule => {
  // Example of a component-as-decorator, see ./helloWorld.js.
  // helloWorld(ngModule);
  nepButton(ngModule);
  nepCard(ngModule);
  nepCardTitle(ngModule);
  nepCheckbox(ngModule);
  presurveyEpAssent(ngModule);
  presurveyEpBlockSwitcher(ngModule);
  presurveyAscend20Validation(ngModule);
};

export default registerComponents;
